import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Paginated } from '../../containers/paginated';
import networkIcon from '../../images/chain-icon';
import { useAccountTrades } from '../../state/exchange';
import { fToken } from '../../utils/formatNumber';
import { getExplorerLink, getExplorerTxLink } from '../../web3/token-utils';
import { networks } from '../../web3/web3';
import NoData from '../../images/inline/nodata.inline.svg';

const ChainCell = ({ chainId }) => (<div className="d-flex align-items-center chain-cell">
    <img style={{ width: '15px', height: '15px', marginRight: '0.75rem' }}
        src={networkIcon[networks[+chainId].shortName]} alt="icon" />
    <span>{networks[+chainId].shortName}</span>

</div>)

const PairCell = ({ from, to }) => (<div className="d-flex align-items-center">
    <span style={{ wordBreak: 'break-word' }}>{from?.symbol} / {to?.symbol}</span>
</div>)

function Trades() {
    const [page, setPage] = useState(0);
    const { trades, pageCount } = useAccountTrades(page)

    return <>
        <Col md={12} >
            <table className="table exchange-history">
                <thead>
                    <tr>
                        <th scope="col">Chain</th>
                        <th scope="col">Pair</th>
                        <th className="d-none d-sm-table-cell" scope="col">Spent</th>
                        <th  scope="col">Received</th>
                        <th className="d-none d-sm-table-cell" scope="col">Date</th>
                        <th scope="col">Txid</th>
                    </tr>
                </thead>
                <tbody>
                    {trades && trades.map(item => <tr key={item.id}>
                        <td scope="row">
                            <ChainCell chainId={item.chain} />
                        </td>
                        <td>
                            <PairCell from={item.from} to={item.to} />
                        </td>
                        <td className="d-none d-sm-table-cell" >{fToken(+item.amountIn)} {item?.from?.symbol}</td>
                        <td  >{fToken(+item.amountOut)} {item?.to?.symbol}</td>
                        <td className="d-none d-sm-table-cell" >{new Date(item.date).toLocaleString()}</td>
                        <td>
                            <a href={getExplorerTxLink(item.txid, item.chain)} target="_blank" rel="noopener noreferrer">
                                {item.txid?.slice(0, 3)}...{item.txid.slice(-3)}
                                <svg
                                    style={{
                                        width: 13,
                                        margin: 'auto',
                                        marginLeft: '0.5rem',
                                        top: '-2px',
                                        position: 'relative'
                                    }}
                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                    <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                </svg>
                            </a>
                        </td>
                    </tr>)
                    }
                </tbody>
            </table>
            {(!trades || trades.length === 0) &&
                <Row className="w-100 m-auto">
                    <NoData className="my-3 mx-auto" />
                </Row>
            }
        </Col>
        <Col md={12} className="d-flex justify-content-center flex-column align-items-center">
            <Paginated nextLabel=">" onPageChange={({ selected }) => setPage(selected)} pageCount={pageCount} previousLabel="<" renderOnZeroPageCount={null} />
        </Col>
    </>
}


export default Trades;
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Otc from "../containers/Otc";


const InvestPage = () => {
 
  return (
    <Layout pageInfo={{ pageName: "Trade" }}>
      <ScrollUpButton ContainerClassName="custom-scroll-button" />
      <Seo title="Swap" />
      <Container className="d-flex flex-column justify-content-around mt-5">
        <Row className="mb-5">
          <Col md={12} className="d-flex flex-column align-items-center header-text">
            <Otc />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default InvestPage;

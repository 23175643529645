
import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Orders from "./orders";
import Trades from "./trades";

import classNames from 'classnames';


function ExchangeHistory() {
    const [active, setActive] = useState('orders');
    return <Row className='m-0 exchange-history'>
        <Card className='p-0'>
            <Card.Body>
                <Row className='my-2'>
                    <Col md={4}>Order Book Log</Col>
                    <Col className="d-flex justify-content-end" md={8}>
                        <span onClick={() => setActive('orders')} className={classNames('tab mx-2', { 'active': active == 'orders' })}>
                            Orders
                        </span>
                        <span onClick={() => setActive('trades')} className={classNames('tab mx-2', { 'active': active == 'trades' })}>
                            Trades
                        </span>
                    </Col>
                </Row>
                {active === 'orders' && <Orders />}
                {active === 'trades' && <Trades />}
            </Card.Body>
        </Card>
    </Row>

}

export default ExchangeHistory;
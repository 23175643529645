import React, { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { useBestPrice } from '../../web3/price-data';
import AsyncItem from '../misc/AsyncItem';

const Pnl = ({ startPrice, tokenA, tokenB }) => {
    const [pnl, setPnl] = useState();
    const { price, refreshPrice, swapper } = useBestPrice('1', tokenA, tokenB);

    useEffect(() => {
        if (!price) {
            return;
        }
        const _pnl = (+price - (1 / startPrice)) / (1 / startPrice);
        setPnl((_pnl * 100).toFixed(2));
    }, [price, swapper])

    useInterval(refreshPrice, 5000);

    return <AsyncItem loading={!pnl} width={25} minWidth={25} height={20}>
        <span style={{ color: pnl > 0 ? '#2EFF00' : '#FD004A' }}>{pnl} %</span>
    </AsyncItem>
}

export default Pnl;